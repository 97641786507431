const industryList = [
  { value: 0, text: 'Hospital/Clinic' },
  { value: 1, text: 'Dental' },
  { value: 2, text: 'Laboratory' },
  { value: 3, text: 'Beauty' },
  { value: 4, text: 'Vet' },
  { value: 99, text: 'Others' }
]

export default industryList
